<template>
  <setup-table
    :title="`Trip Type`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <trip-type-form
        ref="tripTypeForm"
        :value="editedItem"
        :tripCategories="tripCategories"
        :vehicleTypes="vehicleTypes"
        @submit="saveItem"
        @closeForm="closeForm"
      ></trip-type-form>
    </template>

    <template #[`item.active`]="{ item }">
      <v-icon small>{{ item.active ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </template>
    <template #[`item.category`]="{ item }">
      <p class="mb-0">{{ tripCategories[item.categoryId].name }}</p>
    </template>
    <template #[`item.costPerMile`]="{ item }">
      <p class="mb-0">{{ toMoney(item.costPerMile, 4) }}</p>
    </template>
    <template #[`item.planningCost`]="{ item }">
      <p class="mb-0">{{ toMoney(item.planningCost) }}</p>
    </template>
    <template #[`item.additionalCost`]="{ item }">
      <p class="mb-0">{{ toMoney(item.additionalCost) }}</p>
    </template>
    <template #[`item.standardFee`]="{ item }">
      <p class="mb-0">{{ toMoney(item.standardFee) }}</p>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_TRIP_TYPES, SAVE_TRIP_TYPE, DELETE_TRIP_TYPES } from '@/store/modules/TripType/actions';
import SetupTable from './SetupTable.vue';
import TripTypeForm from './TripTypeForm.vue';
import { toMoney } from '@/util';

export default {
  name: 'TripTypeSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, TripTypeForm },
  data() {
    return {
      toMoney,
      showForm: false,
      items: [],
      tripCategories: [
        { id: 0, name: 'N/A' },
        { id: 1, name: 'Travel with Students' },
        { id: 2, name: 'Staff Only' },
      ],
      editedItem: {},
      loading: true,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.fetchItems();
  },
  computed: {
    ...mapGetters('tripType', ['allTripTypes']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
    headers() {
      if (this.windowWidth > 1550)
        return [
          { text: 'Trip Type', value: 'name' },
          { text: 'Category', value: 'category' },
          { text: 'Cost/Mi', value: 'costPerMile' },
          { text: 'Plan Cost', value: 'planningCost' },
          { text: 'Add Cost', value: 'additionalCost' },
          { text: 'Std Fee', value: 'standardFee' },
          { text: 'Active', value: 'active' },
        ];
      else
        return [
          { text: 'Trip Type', value: 'name' },
          { text: 'Category', value: 'category' },
          { text: 'Cost/Mi', value: 'costPerMile' },
          { text: 'Active', value: 'active' },
        ];
    },
  },
  methods: {
    ...mapActions('tripType', [GET_TRIP_TYPES, SAVE_TRIP_TYPE, DELETE_TRIP_TYPES]),
    async fetchItems() {
      await this.getTripTypes({ forTable: true });
      this.items = this.allTripTypes.map((e) => ({ ...e, cannotDelete: e.cannotDelete || e.id <= 4 }));
      this.loading = false;
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0, name: '' };
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveTripType(item);
        if (r && r.id) {
          this.$myalert.success('Trip Type saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Trip Types' : 'this Trip Type'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteTripTypes(items.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${items.length > 1 ? 'Trip Types' : 'Trip Type'} deleted`);
          this.showForm = false;
          this.fetchItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
